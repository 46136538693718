import CompetitiveDiagnostic from "components/msd/CompetitiveDiagnostic/CompetitiveDiagnostic";
import CompetitiveEnvironment from "components/msd/CompetitiveEnvironment/CompetitiveEnvironment";
import CurrentMonthPerformance from "components/msd/CompetitivePositioning/CompetitivePositioning";
import FareImprovements from "components/msd/FareImprovements/FareImprovements";
import KPIMini from "components/msd/KPIMini/KPIMini";
import MarketProfile from "components/msd/MarketProfile/MarketProfile";
import RecommendedMarketActions from "components/msd/RecommendedMarketActions/RecommendedMarketActions";
import RecommendedStrategy from "components/msd/RecommendedStrategy/RecommendedStrategy";
import ShareAdjustments from "components/msd/ShareAdjustments/ShareAdjustments";
import { useContext, useEffect, useState } from "react";
import Layout from "components/common/Layout/Layout";
import { UserProfileContext } from "context-api/UserProfile";

export default function STRATEGYACTIONS() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [data, setData] = useState({
    market_details: {
      competitive_environment: [],
      market_profile: [],
    },
    metrics: {
      fare_gap: {
        title: "",
        value: "",
      },
      fare_variance: {
        title: "",
        value: "",
      },
      host_avg_fare: {
        title: "",
        value: "",
      },
      host_fair_share: {
        title: "",
        value: "",
      },
      host_pax: {
        title: "",
        value: "",
      },
      host_share: {
        title: "",
        value: "",
      },
      host_share_gap: {
        title: "",
        value: "",
      },
      industry_avg_fare: {
        title: "",
        value: "",
      },
      industry_pax: {
        title: "",
        value: "",
      },
      market_size: {
        title: "",
        value: "",
      },
    },
    revopt_fare_improvement: "",
    revopt_share_adjustment: "",
    strategy: {
      competitive_diagnostic: {
        title: "",
        value: [],
      },
      recommended_market_actions: {
        title: "",
        value: [],
      },
      recommended_strategy: {
        title: "",
        value: [],
      },
    },
  });
  const [storyText, setStoryText] = useState({
    comp_environ: {
      content: "",
      title: "",
    },
    curr_month_perf: {
      content: "",
      title: "",
    },
    market_profile: {
      content: "",
      title: "",
    },
    rec_market_actions: {
      content: "",
      title: "",
    },
  });
  const { user } = useContext(UserProfileContext);
  useEffect(() => {
    const fakeData: any = {
      bottom_left: "Low Fare/Low Market Share",
      bottom_right: "Low Fare/High Market Share",
      carriers: {
        All: "#02ECE6",
        XX: "#295CFF",
        YY: "#943BFF",
      },
      fig: {
        data: [
          {
            customdata: [
              ["XX", "10,145", 2022, 11, 110, 46.4, "$ 153"],
              ["YY", "10,145", 2022, 11, 42, 53.2, "$ 202"],
            ],
            hoverinfo: "text",
            hovertemplate:
              "Carrier: %{customdata[0]}<br><br>Travel Year: %{customdata[2]}<br>Travel Month: %{customdata[3]}<br>Market Pax: %{customdata[1]}<br>Flight Count: %{customdata[4]}<br>MS Share: % %{customdata[5]}<br>Avg Fare: %{customdata[6]}<br>Average LF:<br>Breakeven LF:<br><extra></extra>",
            marker: {
              color: ["#100048", "#230047"],
              gradient: {
                color: ["#295CFF", "#943BFF"],
                type: "radial",
              },
              line: {
                color: ["#100048", "#230047"],
                width: 0,
              },
              size: [4712, 5397],
              sizemode: "area",
            },
            mode: "markers+text",
            text: ["XX", "YY"],
            textfont: {
              color: "rgb(255, 255, 255)",
              family: "Montserrat",
              size: 13,
            },
            type: "scatter",
            x: [-54.25604209706899, -39.57142744069703],
            y: [-19.931195016415423, 28.98246361000461],
          },
        ],
        layout: {
          autosize: false,
          height: 400,
          margin: {
            b: 10,
            l: 0,
            pad: 4,
            r: 0,
            t: 0,
          },
          paper_bgcolor: "rgb(18, 13, 34)",
          plot_bgcolor: "rgb(18, 13, 34)",
          showlegend: false,
          title: {
            font: {
              color: "rgb(174, 181, 191)",
              family: "Open Sans",
              size: 23,
            },
            x: 0.5,
          },
          width: 1200,
          xaxis: {
            autorange: false,
            gridcolor: "rgb(248, 235, 247)",
            range: [-100, 100],
            showgrid: false,
            tickfont: {
              color: "rgb(174, 181, 191)",
              family: "Open Sans",
              size: 13,
            },
            title: {
              font: {
                color: "rgb(174, 181, 191)",
                family: "Open Sans",
                size: 15,
              },
              text: "Fare Gap",
            },
            zeroline: true,
            zerolinecolor: "#1e192d",
            zerolinewidth: 1,
          },
          yaxis: {
            autorange: false,
            gridcolor: "rgb(248, 235, 247)",
            range: [-100, 100],
            tickfont: {
              color: "rgb(174, 181, 191)",
              family: "Open Sans",
              size: 13,
            },
            title: {
              font: {
                color: "rgb(174, 181, 191)",
                family: "Open Sans",
                size: 15,
              },
              text: "Share Gap",
            },
            zeroline: true,
            zerolinecolor: "#1e192d",
            zerolinewidth: 1,
          },
        },
      },
      market_details: {
        competitive_environment: [
          "Both host and main competitor have\nan average of 3 connecting\nflights per week.",
        ],
        market_profile: [
          "Host gets a larger portion of\nit’s bookings from Ghana followed\nby the US, whereas the\nsecond contributor for the competitor\nis Kenya.",
        ],
      },
      metrics: {
        fare_gap: {
          title: "Fare Gap",
          value: "-$181.00",
        },
        fare_variance: {
          title: "Fare Var",
          value: "-54.26%",
        },
        host_avg_fare: {
          title: "Host Avg Fare",
          value: "$152.60",
        },
        host_fair_share: {
          title: "Host Fair Share",
          value: "66.38%",
        },
        host_pax: {
          title: "Host Pax",
          value: "4,712",
        },
        host_share: {
          title: "Host Share",
          value: "46.45%",
        },
        host_share_gap: {
          title: "Host Share Gap",
          value: "-19.93%",
        },
        industry_avg_fare: {
          title: "Industry Avg Fare",
          value: "$333.60",
        },
        industry_pax: {
          title: "Industry Pax",
          value: "5,433",
        },
        market_size: {
          title: "Market Size",
          value: "10,145",
        },
      },
      revopt_fare_improvement: "$852,860",
      revopt_share_adjustment: "$308,562",
      story_text: {
        comp_environ: {
          content: "test",
          title: "test",
        },
        curr_month_perf: {
          content: "test",
          title: "test",
        },
        market_profile: {
          content: "test",
          title: "test",
        },
        rec_market_actions: {
          content: "test",
          title: "test",
        },
      },
      strategy: {
        competitive_diagnostic: {
          title: "Competitive Diagnostic",
          value: ["Low Fares + Low Bookings"],
        },
        recommended_market_actions: {
          title: "Recommended Market Actions",
          value: [
            {
              category: "table",
              sub_titles: ["Display recommended fare structure"],
              title: "Ensure small RBD jumps at lower end of fare structure",
            },
            {
              category: "table",
              sub_titles: ["Display recommended fare structure"],
              title: "Ensure large RBD jumps at higher end of fare structure",
            },
            {
              category: "text",
              sub_titles: [""],
              title:
                "Introduce targeted promotions through specific channels (e.g. specific agents)",
            },
            {
              category: "text",
              sub_titles: [""],
              title:
                "Review private fares and agency sales  and offer special packages ( I.e.gambling group packages) to increase sales volume without dilution or driving public fares lower",
            },
            {
              category: "text",
              sub_titles: [""],
              title: "Review price elasticity to ensure best balance of fares",
            },
          ],
        },
        recommended_strategy: {
          title: "Recommended Strategy",
          value: ["1- Opportunistic", "2- Focus on increasing traffic"],
        },
      },
      top_left: "High Fare/Low Market Share",
      top_right: "High Fare/High Market Share",
    };
    if (user?.username === "ethiopia") setData(fakeData);
  }, []);
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Strategy Actions"
    >
      <KPIMini filterIsOpen={filterIsOpen} />

      <div
        style={{
          display: "flex",
          gap: 8,
        }}
      >
        <CompetitiveDiagnostic data={data?.strategy?.competitive_diagnostic} />
        <RecommendedStrategy data={data?.strategy?.recommended_strategy} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 8,
        }}
      >
        <MarketProfile
          data={data ? data?.market_details.market_profile : []}
          storyText={storyText}
        />
        <CompetitiveEnvironment
          data={data?.market_details.competitive_environment}
          storyText={storyText}
        />
      </div>

      <CurrentMonthPerformance
        filterIsOpen={filterIsOpen}
        data={data}
        setOtherDatas={setData}
        storyText={storyText}
        setStoryText={setStoryText}
      />

      <div
        style={{
          display: "flex",
          gap: 8,
        }}
      >
        <ShareAdjustments data={data?.revopt_share_adjustment} />
        <FareImprovements data={data?.revopt_fare_improvement} />
      </div>
      <RecommendedMarketActions
        data={data?.strategy?.recommended_market_actions}
        storyText={storyText}
      />
    </Layout>
  );
}
